import ContactUsBelow from "../components/ContactUsBelow";
import ContentBelow from "../components/ContentBelow";
import Head from "../components/Head";
import background from "../images/services_background.png";
import RAFAdvance from "../components/Forms/RAF-Advance";
import { useState, useEffect } from "react";
import PropertySellers from "../components/Forms/PropertySellers";
import AttorneyForm from "../components/Forms/AttorneyForm";
import classess from "../components/ApplyNow.module.css";
import PropertyAIDA from "../components/Forms/PropertyAIDA";
import PropertyOthers from "../components/Forms/PropertyOTHERS";
import AidaImg from "../images/Aida.png";
import { useLocation } from "react-router-dom";
import DynamicPage from "../components/lib/meta/DynamicPage";
import LinkButtons from "../components/LinkButtons";
import AIDAFile from "../components/downloads/AIDA.pdf";
import WBFile from "../components/downloads/WB.pdf";
import { Link } from "react-router-dom";
import THPropOther from "./thankyou/th-prop-other";
function Applynow() {
  const location = useLocation();
  const [RafFormIsVisible, setRafFromIsVisible] = useState(true);
  const [PropertySellersFormIsVisible, setPropertySellersIsVisible] =
    useState(false);
  const [AttorneyFormIsVisible, setAttorneyIsVisible] = useState(false);
  const [PropertyAIDAIsVisible, setPropertyAIDAIsVisible] = useState();
  const [PropertyOthersIsVisible, setPropertyOthersIsVisible] = useState();
  const [currentHeading, setHeading] = useState(
    "Apply Now for RAF Bridging Finance"
  );
  const [upperHeading, setUpperHeading] = useState("Apply Now");

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get("showRafForm") === "true") {
      setHeading("RAF Bridging Finance Application");
      setUpperHeading("Apply Now for RAF Advance");
      setRafFromIsVisible(true);
      setAttorneyIsVisible(false);
      setPropertyAIDAIsVisible(false);
      setPropertySellersIsVisible(false);
      setPropertyOthersIsVisible(false);
    } else if (params.get("showAttorneyForm") === "true") {
      setHeading("Attorney Bridging Finance Application");
      setUpperHeading("Apply Now for Attorney Fee Advance");
      setPropertyAIDAIsVisible(false);
      setAttorneyIsVisible(true);
      setRafFromIsVisible(false);
      setPropertySellersIsVisible(false);
      setPropertyOthersIsVisible(false);
    } else if (params.get("showPropertyForm") === "true") {
      setHeading("Property Bridging Finance Application");
      setUpperHeading("Apply Now for Property Advance");
      setAttorneyIsVisible(false);
      setRafFromIsVisible(false);
      setPropertySellersIsVisible(true);
      setPropertyOthersIsVisible(false);
      setPropertyAIDAIsVisible(false);
    } else if (params.get("showPropertyAIDAForm") === "true") {
      setHeading("Welcome AIDA Agents.");
      setUpperHeading("AIDA Agents");
      setPropertyAIDAIsVisible(true);
      setAttorneyIsVisible(false);
      setRafFromIsVisible(false);
      setPropertySellersIsVisible(false);
      setPropertyOthersIsVisible(false);
    } else if (params.get("showPropertyOthersForm") === "true") {
      setHeading("Welcome Attorney & Agents.");
      setUpperHeading("Apply Now for Property Advance");
      setPropertyAIDAIsVisible(false);
      setAttorneyIsVisible(false);
      setRafFromIsVisible(false);
      setPropertySellersIsVisible(false);
      setAttorneyIsVisible(false);
      setPropertyOthersIsVisible(true);
    }
  }, [location.search]); // re-run when location.search changes
  function showPropertyForm() {
    setPropertySellersIsVisible(true);
    setHeading("Property Bridging Finance Application");
    setUpperHeading("Apply Now for Property Advance");
    setRafFromIsVisible(false);
    setAttorneyIsVisible(false);
  }
  function showRafForm() {
    setRafFromIsVisible(true);
    setHeading("RAF Bridging Finance Application");
    setUpperHeading("Apply Now for RAF Advance");
    setPropertySellersIsVisible(false);
    setAttorneyIsVisible(false);
  }
  function showAttorneyForm() {
    setPropertySellersIsVisible(false);
    setHeading("Attorney Bridging Finance Application");
    setUpperHeading("Apply Now for Attorney Fee Advance");
    setRafFromIsVisible(false);
    setAttorneyIsVisible(true);
  }
  return (
    <>
      <DynamicPage title="Apply Now" />
      <Head imageUrl={background}>
        <h2 className="heading_space">{upperHeading}</h2>
      </Head>
      <ContentBelow>
        <div className="container">
          <div className="row">
            <div className="col-sm-4 col-lg-4 col-md-4">
              <h3>{currentHeading}</h3>
              {PropertySellersFormIsVisible ? (
                <>
                  <DynamicPage title={upperHeading} />
                  <p>
                    Use this form to apply for property bridging for yourself or
                    your client.
                  </p>
                  <p>
                    If you would like to fast-track your application, please
                    download the application form{" "}
                    <Link to={WBFile} target="_blank" download>
                      here
                    </Link>{" "}
                    and email the PDF form to prop@webridge.co.za.
                  </p>
                </>
              ) : null}
              {PropertyAIDAIsVisible ? (
                <>
                  <DynamicPage title={upperHeading} />

                  <p>
                    Use this form to apply for property bridging for yourself or
                    your client.
                  </p>
                  <p>
                    If you would like to fast-track your application, please
                    download the application form{" "}
                    <Link to={AIDAFile} target="_blank" download>
                      here
                    </Link>{" "}
                    and email the PDF form to prop@webridge.co.za.
                  </p>
                  <img src={AidaImg} />
                </>
              ) : null}

              {AttorneyFormIsVisible ? (
                <>
                  <DynamicPage title={upperHeading} />
                  <p>
                    RAF Attorneys – Complete this form to apply for an Attorney
                    Advance
                  </p>

                  <p>
                    For RAF Claimant Bridging Finance, click
                    <button className={classess.button} onClick={showRafForm}>
                      here
                    </button>
                  </p>
                </>
              ) : null}
              {PropertyOthersIsVisible ? (
                <>
                  <DynamicPage title={upperHeading} />
                  <p>
                    Use this form to apply for property bridging for yourself or
                    your client.
                  </p>
                  <p>
                    If you would like to fast-track your application, please
                    download the application form{" "}
                    <Link to={WBFile} target="_blank" download>
                      here
                    </Link>{" "}
                    and email the PDF form to prop@webridge.co.za.
                  </p>
                </>
              ) : null}

              {RafFormIsVisible ? (
                <>
                  <DynamicPage title={upperHeading} />
                  <p>
                    RAF Claimants – Complete this form to apply for a RAF
                    Advance
                  </p>
                  <p>
                    RAF Attorneys – To apply for a Fee Advance, click
                    <button
                      className={classess.button}
                      onClick={showAttorneyForm}
                    >
                      here
                    </button>
                  </p>
                  {/* <p>
                    For RAF Bridging Finance click
                    <button className={classess.button} onClick={showRafForm}>
                      here
                    </button>
                  </p> */}
                </>
              ) : null}
              {/* <p>
                For any other bridging finance requests, please send an email to
                <a
                  href="mailto: apply@webridge.co.za"
                  style={{ margin: "5px" }}
                >
                  apply@webridge.co.za
                </a>
              </p> */}
            </div>
            <div className=" col-sm-6 col-lg-6 col-md-6">
              {RafFormIsVisible ? <RAFAdvance /> : null}
              {PropertySellersFormIsVisible ? <PropertySellers /> : null}
              {AttorneyFormIsVisible ? <AttorneyForm /> : null}
              {PropertyAIDAIsVisible ? <PropertyAIDA /> : null}
              {PropertyOthersIsVisible ? <PropertyOthers /> : null}
            </div>
          </div>
        </div>
      </ContentBelow>
      <ContactUsBelow />
    </>
  );
}
export default Applynow;
