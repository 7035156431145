import Head from "../../components/Head";
import background from "../../images/services_background.png";
import classes from "../../components/Services.module.css";
import Button from "../../components/Button";
import ContentBelow from "../../components/ContentBelow";
import RAFAdvance from "../../components/Forms/RAF-Advance";

function LayoutPage({ children, Form, heading }) {
  return (
    <>
      <Head imageUrl={background}>
        <h2 className="heading_space">{heading}</h2>
      </Head>
      <ContentBelow>
        <div
          className="container m-0 mx-auto bg-light p-3"
          style={{ borderRadius: "4%" }}
        >
          {children}
        </div>
        <div className=" container col-7">{Form}</div>
      </ContentBelow>
    </>
  );
}

export default LayoutPage;
