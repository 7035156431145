import classes from "../../components/Services.module.css";

import LayoutPage from "./LayoutPage";
import PropertyFrom from "../../components/PropertyForm";
import Applynow from "../Applynow";
import { useState } from "react";
import PropertyAIDA from "../../components/Forms/PropertyAIDA";
import PropertySellers from "../../components/Forms/PropertySellers";
import PropertyOthers from "../../components/Forms/PropertyOTHERS";

function PropertyBridingFinance() {
  const [PropertySellersFormIsVisible, setPropertySellersIsVisible] =
    useState(false);
  const [PropertyAIDAIsVisible, setPropertyAIDAIsVisible] = useState(false);
  const [PropertyOthersIsVisible, setPropertyOthersIsVisible] = useState(false);
  function setAIDAHandler() {
    setPropertyAIDAIsVisible(true);
    setPropertySellersIsVisible(false);
    setPropertyOthersIsVisible(false);
  }
  function setSellersHandler() {
    setPropertySellersIsVisible(true);
    setPropertyAIDAIsVisible(false);
    setPropertyOthersIsVisible(false);
  }
  function setOthersHandler() {
    setPropertyOthersIsVisible(true);
    setPropertySellersIsVisible(false);
    setPropertyAIDAIsVisible(false);
  }
  return (
    <>
      <LayoutPage
        Form={
          <>
            <h1 className=" text-center">Apply Now</h1>
            <div className="row">
              <p className=" text-center">Choose a form</p>
              <ul className="list-inline d-flex flex-wrap justify-content-center">
                <li className="list-inline-item mb-2">
                  <button
                    className="btn btn-primary w-100 w-md-auto"
                    onClick={setAIDAHandler}
                  >
                    Property - AIDA Agents
                  </button>
                </li>
                <li className="list-inline-item mb-2">
                  <button
                    className="btn btn-primary w-100 w-md-auto"
                    onClick={setSellersHandler}
                  >
                    Property - Seller's
                  </button>
                </li>
                <li className="list-inline-item mb-2">
                  <button
                    className="btn btn-primary w-100 w-md-auto"
                    onClick={setOthersHandler}
                  >
                    Property - Others
                  </button>
                </li>
              </ul>
            </div>
            {PropertyAIDAIsVisible ? (
              <>
                <div className=" container col-sm-12 col-md-12 col-lg-7">
                  <h2 className=" text-center">Property AIDA</h2>
                  <PropertyAIDA />{" "}
                </div>
              </>
            ) : null}
            {PropertySellersFormIsVisible ? (
              <>
                <div className=" container col-sm-12 col-md-12 col-lg-7">
                  <h2 className=" text-center">Property Seller's</h2>
                  <PropertySellers />
                </div>
              </>
            ) : null}
            {PropertyOthersIsVisible ? (
              <>
                <div className=" container col-sm-12 col-md-12 col-lg-7">
                  <h2 className=" text-center">Property Others</h2>
                  <PropertyOthers />
                </div>
              </>
            ) : null}
          </>
        }
        heading="PROPERTY BRIDGING FINANCE"
      >
        <div className="row">
          <div className="col-md-7 col-lg-7 mb-3 mb-md-0">
            <h1 className={classes.h1}>PROPERTY BRIDGING FINANCE</h1>
            <p className={classes.p}>
              Property Bridging Finance is a short-term financial solution
              designed to provide immediate access to funds while waiting for a
              property transaction to be finalized. If you're waiting for the
              proceeds from your property sale, this type of finance bridges the
              gap, ensuring you have the necessary liquidity to manage your
              financial obligations.
            </p>
            <h4 className={classes.h4}>What You Need to Apply:</h4>
            <ol className={classes.ol}>
              <li>Sale Agreement</li>
              <li>Personal Identification</li>
              <li>Property Details</li>
              <li>Attorney Details</li>
            </ol>
            <h4 className={classes.h4}>
              How Long Until You Receive Your Funds?
            </h4>
            <p className={classes.p}>
              Once all documents are signed, funds are typically disbursed
              within 24 to 48 hours. This quick turnaround ensures that you can
              meet your financial needs without delay.
            </p>
          </div>
          <div className="col-md-4 col-lg-5">
            <div className={classes["background-image-div-2"]}></div>
          </div>
        </div>
      </LayoutPage>
    </>
  );
}

export default PropertyBridingFinance;
