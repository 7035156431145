import LogoImg from "../images/Join-the-SA-Road-Accident-Support-Group-500-x-158-px.png";
import BFAImg from "../images/1.png";
import MVAccidentLogo from "../images/personal-injury-support-fund-LOGO.png";
import FacebookLogo from "../images/2.png";
import LinkButtons from "./LinkButtons";
// import { Link } from "react-router-dom";
import { Link } from "@react-email/components";
import Button from "../components/Button";
function Footer() {
  return (
    <>
      <footer className="container-fluid footer">
        <div className=" container mx-auto">
          <div className="row">
            {/* <!-- Logo and description --> */}
            <div className="col-lg-3 col-md-6 mb-4">
              <div className="footer-logo">
                <div>
                  <a href="https://www.webridge.co.za/">
                    <img className=" img-fluid" src={LogoImg} />
                  </a>

                  <p>
                    WeBridge provides fast and reliable bridging finance
                    solutions for RAF claimants and others.
                  </p>
                </div>
              </div>
            </div>

            {/* <!-- Useful Links --> */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h5>Useful Links</h5>
              <ul className="list-unstyled">
                <li className=" a">
                  <LinkButtons name="Apply Now" link="/Applynow"></LinkButtons>
                </li>
                <li>
                  <LinkButtons link="/Services" name="Services"></LinkButtons>
                </li>
                <li>
                  <LinkButtons name="Contact" link="/Contact"></LinkButtons>
                </li>
                <li>
                  <LinkButtons name="Privacy" link="/Privacy"></LinkButtons>
                </li>
              </ul>
            </div>

            {/* <!-- BFASA Membership --> */}
            <div className="col-lg-3 col-md-6 mb-4">
              <h5>We are a proud member of</h5>
              <a href="https://bfasa.org.za/" target="_blank">
                <img
                  src={BFAImg}
                  alt="BFASA Logo"
                  width={300}
                  // className="img-fluid"
                />
              </a>
            </div>

            {/* <!-- Support Group --> */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h5>Need support?</h5>
              <a
                href="https://web.facebook.com/groups/SARoadAccidentSupportGroup"
                className="d-flex align-items-center"
              >
                <img
                  src={FacebookLogo}
                  alt="Facebook"
                  className="me-2"
                  width={300}
                />
              </a>
            </div>
          </div>
          <div className="row">
            {/* <!-- Support Motor Vehicle Accident Fund --> */}
            <div className="col-lg-3 col-md-6 mb-4">
              <h5>We proudly support</h5>
              <a href="https://motorvehicleaccidentfund.co.za/" target="_blank">
                <img
                  src={MVAccidentLogo}
                  alt="Motor Vehicle Accident Fund"
                  className="img-fluid"
                />
              </a>
            </div>
            {/* <!-- Follow Us --> */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h5>Follow us</h5>
              <div className="social-icons d-flex">
                <a
                  href="https://web.facebook.com/WeBridgeFinance"
                  target="_blank"
                  className="me-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/company/73181064/admin/dashboard/"
                  target="_blank"
                  className="me-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-linkedin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/webridgefinance/"
                  target="_blank"
                  className="me-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className=" bg-black text-center p-3">
        <p>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.webridge.co.za"
          >
            © 2025 WeBridge Finance
          </a>
        </p>
      </div>
    </>
  );
}
export default Footer;
