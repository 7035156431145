import HomePage from "./components/sub/HomePage";
import DynamicPage from "./components/lib/meta/DynamicPage";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Applynow from "./pages/Applynow";
import Careers from "./components/Careers";
import Blog from "./pages/Blog";
import EmailDisclaimer from "./pages/landing/email-disclaimer";
import Propertey from "./pages/landing/Propertey";
import ScrollToTop from "./components/ScrollToTop";
import Privacy from "./pages/landing/Privacy";
import ApplyAttorney from "./components/Forms/Apply-now-Attorney";
import ApplyProperty from "./components/Forms/Apply-now-property";
import PropertyFrom from "./components/PropertyForm";
import RAFAdvance from "./components/Forms/RAF-Advance";
import RAFThankYou from "./pages/thankyou/th-raf-c";
import THPropOther from "./pages/thankyou/th-prop-other";
import THPropSell from "./pages/thankyou/th-prop-sel";
import THaida from "./pages/thankyou/th-aida";
import RAFthAttorney from "./pages/thankyou/th-raf-a";
import ContactForm from "./components/Forms/TestForm";
import Topbar from "./components/topBar";
import RAFBridingFinance from "./pages/services/RAF-Bridging-Finance";
import PropertyBridingFinance from "./pages/services/Property-Briding-Finance";
import AttorneyBridgeFinance from "./pages/services/Attorney-Briding-Finance";
import BusinessGrowthFinance from "./pages/services/Business-Growth-Finance";
import ThankYou from "./components/ThankYou";
import ReactGA from "react-ga4";

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-D03B5V25E2"); // Replace with your Measurement ID
    ReactGA.send("pageview"); // Log initial pageview
  }, []);
  return (
    <>
      {/* Elements that appear on all pages */}

      {/* Dynamic Page Content */}
      <DynamicPage
        title="WeBridge – Upfront Payment on Settled RAF Claims"
        description="Webridge"
      />

      {/* Define Routes */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Propertey" element={<Propertey />} />
        <Route path="/email-disclaimer" element={<EmailDisclaimer />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/RAF-Advance" element={<RAFAdvance />} />
        <Route path="/Applynow" element={<Applynow />} />
        <Route path="/ProperteyForm" element={<PropertyFrom />} />
        <Route path="/Apply-now-Attorney" element={<ApplyAttorney />} />
        <Route path="/Apply-now-property" element={<ApplyProperty />} />
        <Route path="/ContactForm" element={<ContactForm />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route path="/th-raf-c" element={<RAFThankYou />} />
        <Route path="/th-prop-other" element={<THPropOther />} />
        <Route path="/th-prop-sel" element={<THPropSell />} />
        <Route path="/th-aida" element={<THaida />} />
        <Route path="/th-raf-a" element={<RAFthAttorney />} />

        <Route path="/RAF-Briding-Finance" element={<RAFBridingFinance />} />
        <Route
          path="/Property-Briding-Finance"
          element={<PropertyBridingFinance />}
        />
        <Route
          path="/Attorney-Bridging-Finance"
          element={<AttorneyBridgeFinance />}
        />
        <Route
          path="/Business-Growth-Finance"
          element={<BusinessGrowthFinance />}
        />
      </Routes>
    </>
  );
}

export default App;
