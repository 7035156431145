import Head from "../../components/Head";
import background from "../../images/services_background.png";
import classes from "../../components/Services.module.css";
import Button from "../../components/Button";
import ContentBelow from "../../components/ContentBelow";
import RAFAdvance from "../../components/Forms/RAF-Advance";
import LayoutPage from "./LayoutPage";

function RAFBridingFinance() {
  return (
    <>
      <LayoutPage
        Form={
          <>
            <h1 className=" text-center">Apply Now</h1>
            <RAFAdvance />
          </>
        }
        heading="RAF BRIDGING FINANCE"
      >
        <div className=" row">
          <div className="col-md-7 col-lg-7 mb-3 mb-md-0">
            <h1 className={classes.h1}>RAF BRIDGING FINANCE</h1>
            <p className={classes.p}>
              RAF Bridging Finance is a financial solution designed to provide
              immediate funds to individuals who are awaiting compensation from
              the Road Accident Fund (RAF). This type of financing is
              particularly useful for those who have successfully lodged a claim
              with the RAF but face delays in receiving their settlement.
            </p>
            <h4 className={classes.h4}>What You Need to Apply:</h4>
            <ol className={classes.ol}>
              <li>
                You need a settled claim with the RAF with a valid Court
                Order/Settlement Letter”
              </li>
              {/* <li>
                    Approved RAF Claim: You must have an approved claim with the
                    Road Accident Fund.
                  </li>

                  <li>Court Order / Settlement Letter</li> */}
              <li>ID Document</li>
              <li>Proof of Residence</li>
              <li>Attorney Details</li>
            </ol>
            <h4 className={classes.h4}>
              How Long Until You Receive Your Funds?
            </h4>
            <p className={classes.p}>
              Once all documents are signed, funds are typically disbursed
              within 24 to 48 hours. This quick turnaround ensures that you can
              meet your financial needs without delay.
            </p>
          </div>
          <div className="col-md-4 col-lg-5">
            <div className={classes["background-image-div-1"]}></div>
          </div>
        </div>
      </LayoutPage>
    </>
  );
}

export default RAFBridingFinance;
