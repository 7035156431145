import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactDOMServer from "react-dom/server";
import ClientEmail from "./emailTemplate/ClientEmail";
import { readFromDatabase } from "../lib/reference";

function PropertyOthers() {
  const navigate = useNavigate();
  const [phoneNumber, setPhonenumber] = useState("");
  const [nameSurename, setNameSurename] = useState("");
  const [email, setEmail] = useState("");
  const [otpSigned, setOtpSigned] = useState("");
  const [advanceType, setAdvanceType] = useState("");
  const [refrenceNumber, setRefrenceNumber] = useState("");
  const [fetchRef, setFetchRef] = useState(false);

  useEffect(() => {
    if (fetchRef) {
      const fetchRefNumber = async () => {
        try {
          const ref = await getRefNumber();
          setRefrenceNumber(ref);

          // Ensure the reference number is passed in the email function
          setTimeout(() => {
            sendMail({
              nameSurename,
              phoneNumber,
              advanceType,
              email,
              otpSigned,
              refrenceNumber: ref, // Pass the reference number explicitly
            });
          }, 2000);
        } catch (error) {
          console.error("Error fetching reference number:", error);
        }
      };

      fetchRefNumber();
    }
  }, [fetchRef]);

  // Handle the radio button change
  const handleOTPChange = (e) => {
    setOtpSigned(e.target.value); // Update state with the selected value
  };
  // Handle the radio button change
  const handleAdvanceTypeChange = (e) => {
    setAdvanceType(e.target.value); // Update state with the selected value
  };
  const getRefNumber = async () => {
    return await readFromDatabase(); // Update state with the selected value
  };
  function sendMail({
    nameSurename,
    email,
    phoneNumber,
    otpSigned,
    advanceType,
    refrenceNumber,
  }) {
    console.log("Sending Email");

    if (nameSurename && email && phoneNumber && otpSigned && advanceType) {
      localStorage.setItem("name", nameSurename);
      localStorage.setItem("email", email);
      localStorage.setItem("tel", phoneNumber);
      localStorage.setItem("OTP", otpSigned);
      localStorage.setItem("advanceType", advanceType);

      // Prepare the FormData to include attachments
      const formData = new FormData();

      formData.append(
        "message",
        `Name & Surname: ${nameSurename} <br/> Phone: ${phoneNumber} <br/> Email: ${email} <br/> OTP: ${otpSigned} <br/> Advance Type: ${advanceType} <br/> Refrence: ${refrenceNumber} <br/>`
      );
      formData.append("subject", "Property OTHER");
      formData.append("to", "prop@webridge.co.za");
      //formData.append("to", "rudsfoon@gmail.com");
      // for (let value of formData.values()) {
      //   return console.log(value);
      // }
      // Render the ClientEmail component to a string
      const clientEmailHTML = ReactDOMServer.renderToString(
        <ClientEmail refrence={refrenceNumber} name={nameSurename} />
      );
      //send thank you email to client with ref number
      const clientRef = new FormData();
      clientRef.append("message", clientEmailHTML);
      clientRef.append("to", email);
      clientRef.append("name", nameSurename);
      clientRef.append("subject", `Propertey Other ${refrenceNumber}`);

      // Attach all files from a multiple file input
      const fileInput = document.getElementById("attachment"); // assume there’s an <input type="file" id="attachment" multiple>
      if (fileInput && fileInput.files.length > 0) {
        for (let i = 0; i < fileInput.files.length; i++) {
          formData.append("attachments[]", fileInput.files[i]); // Use "attachments[]" to indicate multiple files
        }
      }

      axios
        .post("https://email-sender-lovat.vercel.app/send_Resend", clientRef, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => alert("Message Sent"))
        .catch(() => alert("Failed to send email"));

      axios
        .post(
          "https://email-sender-lovat.vercel.app/send_Resend",
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => alert("Email Send Success"))
        .catch(() => alert("Failed to send email"));
      return navigate("/th-prop-other");
    }

    return alert("Fill in all fields to continue");
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setFetchRef(true);
        }}
      >
        <input
          className=" form-control"
          value={nameSurename}
          onChange={(e) => setNameSurename(e.target.value)}
          type="text"
          placeholder="Name &amp; Surname"
        />
        <input type="hidden" value={refrenceNumber} readOnly />

        <input
          type="text"
          className=" form-control"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhonenumber(e.target.value)}
        />

        <input
          className=" form-control"
          type="text"
          placeholder="E-Mail Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div>
          <div>
            <table>
              <tr>
                <td>
                  <label htmlFor="otp">Is the OTP signed?</label>
                </td>
                <td>
                  {" "}
                  <label>
                    <input
                      type="radio"
                      name="OTP"
                      value="Yes"
                      checked={otpSigned === "Yes"}
                      onChange={handleOTPChange}
                    />
                    Yes
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="radio"
                      name="OTP"
                      value="No"
                      checked={otpSigned === "No"}
                      onChange={handleOTPChange}
                    />
                    No
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div>
          <table>
            <tr>
              <td>Select type of advance:</td>
              <td>
                {" "}
                <label className="classes.label">
                  <input
                    type="radio"
                    name="advanceType"
                    value="Commission advance"
                    checked={advanceType === "Commission advance"}
                    onChange={handleAdvanceTypeChange}
                  />
                  Commission advance
                </label>
              </td>
              <td>
                <label className="classes.label">
                  <input
                    type="radio"
                    name="advanceType"
                    value="Sellers Advance"
                    checked={advanceType === "Sellers Advance"}
                    onChange={handleAdvanceTypeChange}
                  />
                  Seller’s advance
                </label>
              </td>
            </tr>
          </table>
        </div>
        <br />

        <p>
          For faster processing, include the following documents:
          <br />
          <ul>
            <li>OTP</li>

            <li>ID</li>
            <li>Proof of Address</li>
          </ul>
        </p>
        <p>
          <input
            type="file"
            className=" form-control"
            id="attachment"
            multiple
          />
          <br />
          <small>Hold CTRL in to select multiple files</small>
        </p>

        <button className=" btn btn-primary">Submit</button>
      </form>

      {/* <button onClick={() => readFromDatabase()} className=" btn btn-primary">
        Get Data
      </button> */}
    </>
  );
}

export default PropertyOthers;
