import ContentBelow from "../../components/ContentBelow";
import Head from "../../components/Head";
import background from "../../images/services_background.png";
import classes from "../../components/Propertey.module.css";

function Property() {
  return (
    <>
      <Head imageUrl={background}>
        <h2 className="heading_space">
          <h3>Propertey</h3>
        </h2>
      </Head>
      <ContentBelow>
        <div
          className={`container col-9 ${classes.background_div}`}
          style={{ height: 600 }}
        >
          <div
            style={{
              zIndex: 2,
              backgroundColor: "rgba(128, 128, 128, 0.5)",
              padding: 10,
            }}
          >
            <h1>
              Fast, simple, and secure financing to help you bridge the gap
              until your property sale completes
            </h1>
          </div>
        </div>
      </ContentBelow>
      <div className=" container bg-light p-2">
        <h3>About the service</h3>
      </div>
    </>
  );
}

export default Property;
