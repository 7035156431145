import React, { useState } from "react";
import PaulImg from "../../images/Staff/Paul.png";
import ChloeImg from "../../images/Staff/ChloeCarzis.png";
import JuMari from "../../images/Staff/Ju-MariBishop.png";
import TakalaniImg from "../../images/Staff/TakalaniNd.png";
import TrustImg from "../../images/Staff/TrustNcube.png";
import AmberImg from "../../images/Staff/AmberBird.png";
import HugoImg from "../../images/Staff/HugoLuus.png";
import JJImg from "../../images/Staff/JJGrobler.png";
import MarkImg from "../../images/Staff/MarkBhunu.png";
import TidimaImg from "../../images/Staff/TidimaMakgoka.png";
import ThaboImg from "../../images/Staff/ThaboSeakamela.png";
import LuckyImg from "../../images/Staff/LuckyManganye.png";
import AneImg from "../../images/Staff/AnéMostert.png";
import classes from "../../components/AboutUs.module.css";
const Teamcards = () => {
  const [person, setPerson] = useState([
    {
      title: "Paul Merwe",
      body: "Managing Director",
      img: PaulImg,
      id: 1,
    },
    { title: "Chloë Carzis", body: "Senior Consultant", img: ChloeImg, id: 2 },
    {
      title: "Ju-Mari Bishop",
      body: "Property Bridging Consultant",
      img: JuMari,
      id: 3,
    },
    {
      title: "Takalani Ndou",
      body: "Consultant",
      img: TakalaniImg,
      id: 4,
    },
    {
      title: "Trust Ncube",
      body: "Junior Consultant",
      img: TrustImg,
      id: 5,
    },
    {
      title: "Amber Bird",
      body: "Service Consultant",
      img: AmberImg,
      id: 6,
    },
    {
      title: "Hugo Luus",
      body: "Business Development Manager",
      img: HugoImg,
      id: 7,
    },
    {
      title: "JJ Grobler",
      body: "Software Developer",
      img: JJImg,
      id: 8,
    },
    // {
    //   title: "Mark Bhunu",
    //   body: "Junior Software Developer",
    //   img: MarkImg,
    //   id: 9,
    // },
    // {
    //   title: "Tidima Makgoka",
    //   body: "Software Developer Intern",
    //   img: TidimaImg,
    //   id: 10,
    // },
    {
      title: "Thabo Seakamela",
      body: "Client Consultant Intern",
      img: ThaboImg,
      id: 11,
    },
    // {
    //   title: "Lucky Manganye",
    //   body: "Client Consultant Intern",
    //   img: LuckyImg,
    //   id: 12,
    // },
    {
      title: "Ané Mostert",
      body: "Intern",
      img: AneImg,
      id: 13,
    },
  ]);
  return (
    <div className="row">
      {person.map((person) => (
        <div className=" col-lg-3 col-sm-3 col-md-3">
          <div className="card" style={{ width: "13rem" }}>
            <img src={person.img} class="card-img-top" alt="..." />
          </div>
          <div className={classes.name_block}>
            <h4 className="card-title">{person.title}</h4>
            <p className="card-text">{person.body}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Teamcards;
