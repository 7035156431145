import React from "react";

const logoUrl = "https://www.webridge.co.za/WeBridge-Logo-nobackground.png";

export default function ClientEmail({ refrence, name }) {
  return (
    <div>
      <h3>Thank you for your application {name}!</h3>
      <p>
        Your Refrence number is: <b style={{ color: "red" }}>{refrence}</b>
      </p>

      <img src={logoUrl} width={200} alt="WeBridge Logo" />
    </div>
  );
}
