import Head from "../../components/Head";
import background from "../../images/services_background.png";
import ContentBelow from "../../components/ContentBelow";

function EmailDisclaimer() {
  return (
    <>
      <Head imageUrl={background}>
        <h2 className="heading_space">Email Disclaimer</h2>
      </Head>
      <ContentBelow>
        <div
          className=" container p-3 col-7"
          style={{ fontSize: 16, fontWeight: 300 }}
        >
          <p>EMAIL POLICY AND DISCLAIMER</p>
          <p>CONFIDENTIALITY</p>
          <p>
            This email and any files transmitted with it are strictly
            confidential and intended solely for the individual or entity to
            whom it is addressed. If you have received this email in error,
            please notify us immediately at notify@webridge.co.za . You are
            prohibited from disseminating, distributing, or copying this email
            if you are not the intended recipient. Please notify the sender
            immediately and delete it from your system. Any unauthorized
            disclosure, copying, distribution, or action taken in reliance on
            the contents of this email is strictly prohibited and may be
            unlawful.
          </p>
          <h5>VIRUS OR MALWARE WARNING</h5>
          <p>
            Computer viruses can be transmitted via email and their attachments.
            It is the recipient's responsibility to check this email and any
            attachments for viruses. The Company disclaims all liability for any
            loss or damages incurred as a result of using this email or its
            attachments, including any damage due to viruses or malware. Email
            transmission is not guaranteed to be secure or error-free;
            therefore, the Company does not accept any liability for errors or
            omissions in the content of this email that arise from the
            transmission process. We do not warrant the integrity of this email
            or that it is free from viruses or errors.
          </p>

          <h5>CYBER-CRIME WARNING</h5>
          <p>
            Cyber-crime, including fraudulent emails (often referred to as
            "phishing"), poses a growing threat. Recipients should be vigilant
            against such risks. • Banking Detail Changes via email: Our Company
            will never notify recipients of changes to banking details via
            email. If you receive an email indicating a change to Company
            banking information, it is likely fraudulent. Please verify the
            legitimacy of such information by contacting the Company directly
            using a trusted and independently verified phone number or email
            address, which can be found on our official website or in previous
            reliable email correspondence. Do not use any contact information
            provided in suspicious emails. • Limitation of Liability: Recipients
            are urged to exercise caution when assessing the authenticity of
            emails purportedly sent by the Company. If you rely on fraudulent
            emails despite this clear warning and suffer any loss or damages as
            a result, the Company will not be held liable for such loss or
            damage.
          </p>

          <h5>MISSTATEMENT</h5>
          <p>
            Whilst the Company strives to transmit accurate information via
            emails, we accept no liability for any misstatements or for actions
            taken based on such misstatements.
          </p>

          <h5>EMPLOYEE VIEWS</h5>
          <p>
            The views and opinions expressed in this email are those of the
            author and do not necessarily represent those of the Company.
            Employees are prohibited from making defamatory statements or
            infringing upon copyrighted materials or other legal rights in email
            communications. Any such communication is contrary to Company policy
            and outside the scope of the employee's employment. The Company will
            not be liable for any damages arising from such communications, and
            the responsible employee will be personally liable for any
            consequences.
          </p>
        </div>
      </ContentBelow>
    </>
  );
}

export default EmailDisclaimer;
