import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import ClientEmail from "./emailTemplate/ClientEmail";
import { rafcReadFromDB } from "../lib/reference";

function RAFAdvance() {
  const [phoneNumber, setPhonenumber] = useState("");
  const [nameSurename, setNameSurename] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [settled, setSetteld] = useState("");
  const [attornetFirm, setAttorneyFirm] = useState("");
  const [attorneyName, setAttorneyName] = useState("");
  const [refrenceNumber, setRefrenceNumber] = useState("");
  const [fetchRef, setFetchRef] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchRef) {
      const fetchRefNumber = async () => {
        try {
          const ref = await getRefNumber();
          setRefrenceNumber(ref);

          // Ensure the reference number is passed in the email function
          setTimeout(() => {
            sendMail({
              nameSurename,
              phoneNumber,
              settled,
              attorneyName,
              attornetFirm,
              email,
              refrenceNumber: ref, // Pass the reference number explicitly
            });
          }, 2000);
        } catch (error) {
          console.error("Error fetching reference number:", error);
        }
      };

      fetchRefNumber();
    }
  }, [fetchRef]);

  const getRefNumber = async () => {
    return await rafcReadFromDB(); // Update state with the selected value
  };
  function sendMail({
    nameSurename,
    email,
    settled,
    attorneyName,
    attornetFirm,
    phoneNumber,
    refrenceNumber,
  }) {
    console.log("Sending Email");

    if (
      nameSurename &&
      email &&
      phoneNumber &&
      attornetFirm &&
      attorneyName &&
      settled
    ) {
      localStorage.setItem("name", nameSurename);
      localStorage.setItem("email", email);
      localStorage.setItem("tel", phoneNumber);
      localStorage.setItem("message", message);
      localStorage.setItem("settled", settled);
      localStorage.setItem("attorneyFirm", attornetFirm);
      localStorage.setItem("attorneyName", attorneyName);
      localStorage.setItem("refrenceNumber", refrenceNumber);

      // Prepare the FormData to include attachments
      const formData = new FormData();
      formData.append(
        "message",
        `Name & Surname: ${nameSurename} <br/> Phone: ${phoneNumber} <br/> Email: ${email} <br/> Is your RAF claim settled: ${settled} <br/> Attornet Firm: ${attornetFirm} <br/> Attorney Name: ${attorneyName} <br/> Refrence number: ${refrenceNumber}`
      );
      formData.append("subject", "RAF Advance - Claimants");
      formData.append("to", "apply@webridge.co.za");
      //formData.append("to", "rudsfoon@gmail.com");

      // Render the ClientEmail component to a string
      const clientEmailHTML = ReactDOMServer.renderToString(
        <ClientEmail refrence={refrenceNumber} name={nameSurename} />
      );
      //send thank you email to client with ref number
      const clientRef = new FormData();
      clientRef.append("message", clientEmailHTML);
      clientRef.append("to", email);
      clientRef.append("name", nameSurename);
      clientRef.append("subject", `RAF Advance ${refrenceNumber}`);

      // Attach a file if there is one
      const fileInput = document.getElementById("attachment"); // assume there’s an <input type="file" id="attachment">
      if (fileInput && fileInput.files.length > 0) {
        formData.append("attachment", fileInput.files[0]);
        formData.append("attachmentTwo", fileInput.files[0]);
      }
      axios
        .post("https://email-sender-lovat.vercel.app/send_Resend", clientRef, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => alert("Message Sent"))
        .catch(() => alert("Failed to send email"));

      axios
        .post("https://email-sender-lovat.vercel.app/send_Resend", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => alert("Message Sent"))
        .catch(() => alert("Failed to send email"));
      return navigate("/th-raf-c");
    }
    return alert("Fill in all fields to continue");
  }

  return (
    <>
      <form
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          setFetchRef(true);
        }}
      >
        <input
          className=" form-control"
          type="text"
          id="name"
          placeholder="Name & Surname"
          value={nameSurename}
          onChange={(e) => setNameSurename(e.target.value)}
        />
        <input type="hidden" value={refrenceNumber} readOnly />
        <input
          className=" form-control"
          type="tel"
          id="tel"
          placeholder="Phone number"
          value={phoneNumber}
          onChange={(e) => setPhonenumber(e.target.value)}
        />

        <input
          className=" form-control"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Adress"
        />
        <p>Is your RAF claim settled? *</p>
        <select
          id="settled"
          className=" form-control form-select"
          value={settled}
          onChange={(e) => setSetteld(e.target.value)}
        >
          <option>---</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        <input
          className=" form-control"
          type="text"
          id="attorneyFirm"
          value={attornetFirm}
          onChange={(e) => setAttorneyFirm(e.target.value)}
          placeholder="Attorney Firm that represents you:"
        />

        <input
          className=" form-control"
          type="text"
          id="attorneyName"
          value={attorneyName}
          onChange={(e) => setAttorneyName(e.target.value)}
          placeholder="Your attorney's name (Name & Surname):"
        />

        <button className=" btn btn-primary">Submit</button>
      </form>
    </>
  );
}

export default RAFAdvance;
