import { useNavigate } from "react-router-dom";
import classes from "./Button.module.css"; // Add this to include CSS styling

function LinkButtons({ name, link }) {
  const navigate = useNavigate();

  const handleApply = () => {
    navigate(link);
  };

  return (
    <p onClick={handleApply} className={classes.link}>
      {name}
    </p>
  );
}

export default LinkButtons;
