import axios from "axios";
import ReactDOMServer from "react-dom/server";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import ClientEmail from "../Forms/emailTemplate/ClientEmail";
import { useState } from "react";

async function writeToDatabase(refNumber) {
  //let refNum = await readFromDatabase();
  //refNum = `P${refNum.toString().padStart(4, "0")}`;
  try {
    const response = await axios.post(
      "https://hemsidaemail.vercel.app/api/neon",
      { postData: refNumber }, // Send data as an object
      {
        headers: {
          "Content-Type": "application/json", // Correct Content-Type for JSON data
        },
      }
    );

    console.log("Response:", response.data); // Log the response from the server
  } catch (error) {
    console.error("Error while writing to JSON:", error);
  }
}

export async function readFromDatabase() {
  try {
    const response = await axios.get(
      "https://hemsidaemail.vercel.app/api/neon"
    );
    //check what the last refrence number is and add 1 to it
    if (Array.isArray(response.data)) {
      const references = response.data.map((ref) => ref.reference);
      if (references.length === 0) {
        const start = Number("1");
        writeToDatabase(start);
        const lastReference = response.data.map((reff) => reff.lastReference);
        const newRef = Number(lastReference) + 1;
        const finalRef = `P${newRef.toString().padStart(4, "0")}`;
        //console.log(finalRef);
        return finalRef;
      } else {
        const lastReference = references[references.length - 1];
        const newRef = Number(lastReference) + 1;
        writeToDatabase(newRef);
        const finalRef = `P${newRef.toString().padStart(4, "0")}`;
        //console.log(finalRef);
        return finalRef;
      }
    } else {
      throw new Error("Error in action");
    }
  } catch (error) {
    console.error("Error While reading from database", error);
  }
}

async function rafcWriteToDb(refNumber) {
  try {
    const response = await axios.post(
      "https://hemsidaemail.vercel.app/api/rafc",
      { postDataRafc: refNumber }, // Send data as an object
      {
        headers: {
          "Content-Type": "application/json", // Correct Content-Type for JSON data
        },
      }
    );
    console.log("Response:", response.data); // Log the response from the server
  } catch (error) {
    console.error("Error while writing to JSON:", error);
  }
}
export async function rafcReadFromDB() {
  try {
    const response = await axios.get(
      "https://hemsidaemail.vercel.app/api/rafc"
    );

    //check what the last refrence number is and add 1 to it
    if (Array.isArray(response.data)) {
      const references = response.data.map((raf) => raf.ref); //must be same name as colom name
      if (references.length === 0) {
        const start = Number("1");
        rafcWriteToDb(start);
        const lastReference = response.data.map((raff) => raff.lastReference);
        const newRef = Number(lastReference) + 1;
        const finalRef = `RAFC${newRef.toString().padStart(4, "0")}`;
        //console.log(finalRef);
        return finalRef;
      } else {
        const lastReference = references[references.length - 1];
        const newRef = Number(lastReference) + 1;
        rafcWriteToDb(newRef);
        const finalRef = `RAFC${newRef.toString().padStart(4, "0")}`;
        //console.log(finalRef);
        return finalRef;
      }
    } else {
      throw new Error("Error in rafc action");
    }
  } catch (error) {
    console.error("Error While reading from rafc database", error);
  }
}
//rafa
async function rafaWriteToDb(refNumber) {
  try {
    const response = await axios.post(
      "https://hemsidaemail.vercel.app/api/rafa",
      { postDataRafa: refNumber }, // Send data as an object
      {
        headers: {
          "Content-Type": "application/json", // Correct Content-Type for JSON data
        },
      }
    );
    console.log("Response:", response.data); // Log the response from the server
  } catch (error) {
    console.error("Error while writing to JSON:", error);
  }
}
export async function rafaReadFromDB() {
  try {
    const response = await axios.get(
      "https://hemsidaemail.vercel.app/api/rafa"
    );

    //check what the last refrence number is and add 1 to it
    if (Array.isArray(response.data)) {
      const references = response.data.map((raf) => raf.ref); //must be same name as colom name
      if (references.length === 0) {
        const start = Number("1");
        rafaWriteToDb(start);
        const lastReference = response.data.map((raff) => raff.lastReference);
        const newRef = Number(lastReference) + 1;
        const finalRef = `RAFA${newRef.toString().padStart(4, "0")}`;
        //console.log(finalRef);
        return finalRef;
      } else {
        const lastReference = references[references.length - 1];
        const newRef = Number(lastReference) + 1;
        rafaWriteToDb(newRef);
        const finalRef = `RAFA${newRef.toString().padStart(4, "0")}`;
        //console.log(finalRef);
        return finalRef;
      }
    } else {
      throw new Error("Error in rafc action");
    }
  } catch (error) {
    console.error("Error While reading from rafc database", error);
  }
}

// function goToThankYou() {
//   const navigate = useNavigate();
//   return navigate("/th-prop-other");
// }
// export async function sendMail({
//   nameSurename,
//   email,
//   phoneNumber,
//   otpSigned,
//   advanceType,
//   refrenceNumber,
//   fileInput,
// }) {
//   console.log("Sending Email");

//   if (nameSurename && email && phoneNumber && otpSigned && advanceType) {
//     localStorage.setItem("name", nameSurename);
//     localStorage.setItem("email", email);
//     localStorage.setItem("tel", phoneNumber);
//     localStorage.setItem("OTP", otpSigned);
//     localStorage.setItem("advanceType", advanceType);
//     localStorage.setItem("refrenceNumber", refrenceNumber);

//     // Prepare the FormData to include attachments
//     const formData = new FormData();
//     formData.append(
//       "message",
//       `Name & Surname: ${nameSurename} <br/> Phone: ${phoneNumber} <br/> Email: ${email} <br/> OTP: ${otpSigned} <br/> Advance Type: ${advanceType} <br/> Refrence: ${refrenceNumber} <br/>`
//     );
//     formData.append("subject", "Property OTHER");
//     //formData.append("to", "prop@webridge.co.za");
//     formData.append("to", "rudsfoon@gmail.com");
//     // for (let value of formData.values()) {
//     //   return console.log(value);
//     // }
//     // Render the ClientEmail component to a string
//     const clientEmailHTML = ReactDOMServer.renderToString(
//       <ClientEmail refrence={refrenceNumber} name={nameSurename} />
//     );
//     //send thank you email to client with ref number
//     const clientRef = new FormData();
//     clientRef.append("message", clientEmailHTML);
//     clientRef.append("to", email);
//     clientRef.append("name", nameSurename);
//     clientRef.append("subject", `Propertey Other ${refrenceNumber}`);

//     // Attach all files from a multiple file input
//     fileInput = document.getElementById("attachment"); // assume there’s an <input type="file" id="attachment" multiple>
//     if (fileInput && fileInput.files.length > 0) {
//       for (let i = 0; i < fileInput.files.length; i++) {
//         formData.append("attachments[]", fileInput.files[i]); // Use "attachments[]" to indicate multiple files
//       }
//     }
//     axios
//       .post("https://email-sender-lovat.vercel.app/send_Resend", clientRef, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then(() => alert("Message Sent"))
//       .catch(() => alert("Failed to send email"));

//     axios
//       .post(
//         "https://email-sender-lovat.vercel.app/send_Resend",
//         formData,

//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       )
//       .then(() => alert("Email Send Success"))
//       .catch(() => alert("Failed to send email"));
//   }

//   return alert("Fill in all fields to continue");
// }
