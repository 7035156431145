import React from "react";
import { Helmet } from "react-helmet";

const DynamicPage = ({ title, description, details }) => {
  return (
    <div>
      <Helmet>
        <title>{`${title} - Webridge`}</title>
        <meta name="description" content={description} />
      </Helmet>
    </div>
  );
};

export default DynamicPage;
