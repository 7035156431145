import React from "react";
import classes from "./NavBar.module.css";
import Logo from "./images/WeBridge-Logo-nobackground.png";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

export default function NavBar() {
  return (
    <nav className={`navbar navbar-expand-lg ${classes.navbar}`}>
      <div className="container">
        <div className={classes.logo}>
          <a href="https://www.webridge.co.za">
            <img src={Logo} alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className={`navbar-nav ms-auto  ${classes["nav-links"]}`}>
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/AboutUs">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Contact">
                Contact
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                Apply Now
              </a>
              <ul
                class="dropdown-menu"
                style={{
                  minWidth: "270px",
                  maxWidth: "250px",
                }}
              >
                <li>
                  <Link className="nav-link" to="/Applynow?showRafForm=true">
                    RAF Advance – Claimants
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    to="/Applynow?showAttorneyForm=true"
                  >
                    RAF Advance - Attorneys
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    style={{ width: 160 }}
                    to="/Applynow?showPropertyForm=true"
                  >
                    Property – Sellers
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    style={{ width: 160 }}
                    to="/Applynow?showPropertyAIDAForm=true"
                  >
                    Property–AIDA Agents
                  </Link>
                </li>
                <li>
                  <Link
                    className="nav-link"
                    style={{ padding: "3px", margin: "0px", width: 160 }}
                    to="/Applynow?showPropertyOthersForm=true"
                  >
                    Property – Others
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Careers">
                Careers
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Blog">
                Blog
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
