import Head from "../../components/Head";
import background from "../../images/services_background.png";
import ContentBelow from "../../components/ContentBelow";
import classes from "../../components/Privacy.module.css";

function Privacy() {
  return (
    <>
      <Head imageUrl={background}>
        <h2 className="heading_space">Privacy Policy</h2>
      </Head>

      <div
        className={`container bg-light text-dark p-4 col-lg-7 ${classes.div}`}
      >
        <p>
          WeBridge , referred to below as the “Company”, offer bridging finance
          solutions to RAF Claimnants, RAF Medical Experts and Property Sellers.
          By using the Company website, you are agreeing to the following
          Privacy Policy. PRIVACY NOTICE
        </p>
        <p>
          The Company understands your privacy is important. As described in
          this Privacy Policy, the Company website and free & paid services (the
          “Services”) have been designed to protect information about you from
          unauthorised disclosure to others. This Privacy Policy explains how
          the Company collects, uses and, in certain circumstances, shares your
          information when you use the Services.
        </p>
        <p>
          By using the site, you explicitly consent to the collection and use of
          your information as outlined in this Privacy Policy.
        </p>
        <ol>
          <li>AUTOMATIC INFORMATION</li>
          <p>
            When you use the site, statistics about your website visitors are
            automatically collected. The Company monitors statistics such as how
            many people visit your website, the visitor’s IP address, pages
            visited, entry and exit points, from which domains visitors come and
            browser types. This non-personally-identifiable information may be
            shared with third-parties to provide more relevant services and
            advertisements to users and visitors of the site.
          </p>
          <li>COOKIES</li>
          <p>
            A “cookie” is a piece of data transfered to your computer that
            identifies you as a unique user. Cookies enable sites to recognise
            your browser and to provide features such as customisation. The site
            use cookies to improve the quality of the service and make the
            browing experience better for users. In order to provide you a
            seamless experience during a particular session, the site stores
            information about that session in cookies. You can edit your browser
            options to prevent your browser from accepting new cookies or to
            notify you when you receive a new cookie. If you do not accept
            cookies, however, some features of the site may not function
            properly.
          </p>
          <li>SHARING INFORMATION</li>
          <p>
            Some of your information is visible to other users. The site may use
            third parties to offer other or enhanced Services. Except as
            described in this privacy policy, you will be notified when your
            information may be shared with third parties and be able to opt-out
            of sharing your information.
            <br />
            <br />
            You understand and agree that the Company may access, preserve, and
            disclose your information if required to do so by law or in a good
            faith belief that such access, preservation or disclosure is
            reasonably necessary to avoid liability, to comply with legal
            process, such as a search warrant, subpoena, statute, or court
            order, or to protect the Company rights and property, or that of the
            Company affiliates or the public. In the unlikely event of
            bankruptcy, insolvency, reorganisation, receivership, or assignment
            for the benefit of creditors, or the application of laws or
            equitable principles affecting creditors’ rights generally, the
            Company may not be able to control how your information is treated,
            transferred, or used.
          </p>
          <li>THIRD-PARTY COMPANIES</li>
          <p>
            The site may contain links to other websites. Please be aware that
            the Company is not responsible for the privacy practices of other
            websites. The Company encourages users to be aware when they leave
            this site and to read the privacy statements of each and every
            website that collects personally identifiable information to learn
            more about their information practices.
            <br />
            <br />
            Information collected through the site may be stored and processed
            in any country in which the Company maintains facilities.
            Consequently, the Company has the right to transfer information
            outside of your country. Therefore, you acknowledge and consent to
            the sharing of your information with the Company and Company-related
            entities worldwide.
          </p>
          <li>SECURITY</li>
          <p>
            The site uses industry standard security measures to protect your
            information so that it is not made available to unauthorised
            parties. The Company cannot, however, ensure or warrant the security
            of any information transmitted to the Services and you do so at your
            own risk.
            <br />
            <br />
            Unauthorised entry or use, hardware or software failure, and other
            factors may compromise the security of your information at any time
            so please note that this is not a guarantee that such information
            may not be accessed, disclosed, altered, or destroyed by breach of
            any of the site’s physical, technical, or managerial safeguards. The
            Company may use outside companies to display ads on the site and
            these ads may contain cookies collected by the ad companies. The
            Company does not have access to this information. Also, data
            transmission over the Internet cannot be guaranteed to be totally
            secure. Therefore, although the Company takes measures, such as
            those described in this Privacy Policy, to protect your information,
            the Company cannot warrant the security of information collected.
            Accordingly, you agree that you are providing such information at
            your own risk.
          </p>
          <li>INTERNATIONAL USERS</li>
          <p>
            The systems of the site are hosted in South Africa. If you are a
            user accessing the site from the European Union, Asia, or any other
            region with laws or regulations governing personal data collection,
            use, and disclosure, that differ from South African laws, please be
            advised that through your continued use of the site, which is
            governed by South African, this Privacy Notice, and these Terms of
            Service, you are transferring your information to the South Africa
            and you consent to that transfer.
          </p>
          <li>CHANGES AND UPDATES TO THIS PRIVACY NOTICE</li>
          <p>
            This Privacy Notice may be revised periodically and this will be
            reflected by the Last Updated date at the top of this Privacy
            Notice. Please revisit this page to stay aware of any changes. Your
            continued use of the Services constitutes your agreement to this
            Privacy Notice and any future revisions.
          </p>
          <p>ACCEPTANCE</p>
          <p>
            By using this site you acknowledge and agree to the terms of this
            Privacy Policy. If you disagree with any term or condition in this
            Privacy Policy, please do not use the site. If you have any
            questions about this privacy statement, please contact
            info@webridge.co.za.
          </p>
        </ol>
      </div>
    </>
  );
}

export default Privacy;
