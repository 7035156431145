import Contact from "../pages/Contact";
import ContactUsBelow from "./ContactUsBelow";
import Head from "./Head";
import background from "../images/services_background.png";
import Button from "../components/Button";

function ThankYou() {
  return (
    <>
      <Head imageUrl={background}>
        <h2 className="heading_space">Thank you</h2>
      </Head>
      <div
        className="container col-6 p-4 text-center"
        style={{
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#f8f9fa",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h5 style={{ color: "#2d3748", marginBottom: "20px" }}>
          Thank you for submitting your online application!
        </h5>
        <p style={{ color: "#4a5568", lineHeight: "1.6" }}>
          We appreciate your interest and will respond within one business day.
          <br />
          If you do not hear from us, there might have been a technical issue.
          <br />
          Please call us on our landline to ensure we received your application.
        </p>
        <Button link="/" name="Back Home"></Button>
      </div>

      <ContactUsBelow />
    </>
  );
}
export default ThankYou;
