import React from "react";
import ThankYou from "../../components/ThankYou";

export default function THPropOther() {
  return (
    <div>
      <ThankYou />
    </div>
  );
}
