import classes from "../../components/Services.module.css";
import LayoutPage from "./LayoutPage";

function BusinessGrowthFinance() {
  return (
    <>
      <LayoutPage>
        <div className=" row">
          <div className="col-md-7 col-lg-7 mb-3 mb-md-0">
            <h1 className={classes.h1}>BUSINESS GROWTH FINANCE</h1>
            <p className={classes.p}>
              Business Growth Finance is a suite of financial solutions designed
              to help businesses unlock cash flow and fuel growth by leveraging
              outstanding invoices, purchase orders, and tenders. Whether you're
              looking to expand operations, fulfill large orders, or manage
              working capital, these financing options provide the necessary
              funds to keep your business moving forward
            </p>
            <h5 className={classes.h4}>
              What are the types of Business Growth Finance?
            </h5>
            <ol>
              <li className={classes.h4}>Invoice Discounting</li>
              <p className={classes.p}>
                Invoice Discounting allows businesses to access immediate cash
                by selling their outstanding invoices at a discount. This
                enables companies to maintain cash flow without waiting for
                customers to pay their invoices, ensuring smooth day-to-day
                operations.
              </p>
              <li className={classes.h4}>Purchase Order Funding</li>
              <p className={classes.p}>
                Purchase Order Funding provides businesses with the capital
                needed to fulfill large orders. By advancing funds based on
                confirmed purchase orders, this financing option allows you to
                cover production costs, supplier payments, and other expenses,
                ensuring timely delivery to your clients.
              </p>
              <li className={classes.h4}>Tender Funding</li>
              <p className={classes.p}>
                Tender Funding is designed for businesses that have been awarded
                a tender but lack the upfront capital to complete the project.
                This finance option provides the necessary funds to cover the
                costs associated with fulfilling the tender, such as materials,
                labor, and logistics, allowing you to take on larger projects
                and grow your business.
              </p>
            </ol>
            <p>
              Please visit our affiliate, Steed Growth Finance, to apply and get
              more details.
            </p>
            <div className=" text-center">
              <a
                href="https://www.steedfinance.co.za/"
                className="btn btn-primary btn-lg"
                target="_blank"
              >
                More info
              </a>
            </div>
          </div>
          <div className="col-md-4 col-lg-5">
            <div className={classes["background-image-div-4"]}></div>
          </div>
        </div>
      </LayoutPage>
    </>
  );
}

export default BusinessGrowthFinance;
