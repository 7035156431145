import LayoutPage from "./LayoutPage";
import classes from "../../components/Services.module.css";
import AttorneyForm from "../../components/Forms/AttorneyForm";
function AttorneyBridgeFinance() {
  return (
    <>
      <LayoutPage
        heading="ATTORNEY FINANCE"
        Form={
          <>
            <h1 className=" text-center">Apply Now</h1>
            <AttorneyForm />
          </>
        }
      >
        <div className=" row">
          <div className="col-md-7 col-lg-7 mb-3 mb-md-0">
            {" "}
            <h1 className={classes.h1}>ATTORNEY FINANCE</h1>
            <p className={classes.p}>
              RAF Attorney Finance is a specialized financial service designed
              to advance legal fees for attorneys handling Road Accident Fund
              (RAF) claims. Once a RAF claim has been settled, attorneys often
              face delays in receiving their fees. RAF Attorney Finance provides
              an advance on these fees, ensuring that attorneys can maintain
              their cash flow and continue to operate effectively while waiting
              for the RAF payout.
            </p>
            <h4 className={classes.h4}>What you need to apply</h4>
            <ol className={classes.ol}>
              <li>Settled RAF Claim</li>
              <li>Personal Identification</li>
              <li>ID Document</li>
              <li>Legal Practice Information</li>
            </ol>
            <h4 className={classes.h4}>
              How Long Until You Receive Your Funds?
            </h4>
            <p className={classes.p}>
              Funds are typically disbursed within 24 to 48 hours after the all
              agreements are signed. This swift turnaround ensures that
              attorneys can cover their immediate expenses without having to
              wait for the RAF's often lengthy payment process.
            </p>
          </div>
          <div className="col-md-4 col-lg-5">
            <div className={classes["background-image-div-3"]}></div>
          </div>
        </div>
      </LayoutPage>
    </>
  );
}

export default AttorneyBridgeFinance;
