import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import ClientEmail from "./emailTemplate/ClientEmail";
import { rafaReadFromDB } from "../lib/reference";

function AttorneyForm() {
  const [phoneNumber, setPhonenumber] = useState("");
  const [nameSurename, setNameSurename] = useState("");
  const [email, setEmail] = useState("");
  const [refrenceNumber, setRefrenceNumber] = useState("");
  const [attornetFirm, setAttorneyFirm] = useState("");
  const [fetchRef, setFetchRef] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchRef) {
      const fetchRefNumber = async () => {
        try {
          const ref = await getRefNumber();
          setRefrenceNumber(ref);

          // Ensure the reference number is passed in the email function
          setTimeout(() => {
            sendMail({
              nameSurename,
              attornetFirm,
              phoneNumber,
              email,
              refrenceNumber: ref, // Pass the reference number explicitly
            });
          }, 2000);
        } catch (error) {
          console.error("Error fetching reference number:", error);
        }
      };

      fetchRefNumber();
    }
  }, [fetchRef]);

  const getRefNumber = async () => {
    return await rafaReadFromDB(); // Update state with the selected value
  };

  function sendMail({
    nameSurename,
    email,
    attornetFirm,
    phoneNumber,
    refrenceNumber,
  }) {
    console.log("Sending Email");

    if (nameSurename && email && phoneNumber && attornetFirm) {
      localStorage.setItem("name", nameSurename);
      localStorage.setItem("email", email);
      localStorage.setItem("tel", phoneNumber);
      localStorage.setItem("attorneyFirm", attornetFirm);
      localStorage.setItem("phoneNumber", phoneNumber);
      localStorage.setItem("refrenceNumber", refrenceNumber);

      // Prepare the FormData to include attachments
      const formData = new FormData();
      formData.append(
        "message",
        `Name & Surname: ${nameSurename} <br/> Phone: ${phoneNumber} <br/> Email: ${email}  <br/> Attornet Firm: ${attornetFirm} <br/> Refrence: ${refrenceNumber}`
      );
      formData.append("subject", "RAF Advance - Attorneys");
      formData.append("to", "apply@webridge.co.za");
      //formData.append("to", "rudsfoon@gmail.com");

      // Render the ClientEmail component to a string
      const clientEmailHTML = ReactDOMServer.renderToString(
        <ClientEmail refrence={refrenceNumber} name={nameSurename} />
      );
      //send thank you email to client with ref number
      const clientRef = new FormData();
      clientRef.append("message", clientEmailHTML);
      clientRef.append("to", email);
      clientRef.append("name", nameSurename);
      clientRef.append("tel", phoneNumber);
      clientRef.append("attorneyFirm", attornetFirm);
      clientRef.append("email", email);
      clientRef.append("subject", `RAF Advance - Attorneys ${refrenceNumber}`);

      // Attach a file if there is one
      const fileInput = document.getElementById("attachment"); // assume there’s an <input type="file" id="attachment">
      if (fileInput && fileInput.files.length > 0) {
        formData.append("attachment", fileInput.files[0]);
        formData.append("attachmentTwo", fileInput.files[0]);
      }
      axios
        .post("https://email-sender-lovat.vercel.app/send_Resend", clientRef, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => alert("Message Sent"))
        .catch(() => alert("Failed to send email"));
      axios
        .post("https://email-sender-lovat.vercel.app/send_Resend", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => alert("Message Sent"))
        .catch(() => alert("Failed to send email"));
      return navigate("/th-raf-a");
    }

    return alert("Fill in all fields to continue");
  }
  return (
    <>
      <form
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          setFetchRef(true);
        }}
      >
        <input
          className=" form-control"
          type="text"
          placeholder="Name & Surname"
          value={nameSurename}
          onChange={(e) => setNameSurename(e.target.value)}
        />
        <input type="hidden" value={refrenceNumber} readOnly />

        <input
          className=" form-control"
          type="text"
          placeholder="Attorney Firm Name"
          value={attornetFirm}
          onChange={(e) => setAttorneyFirm(e.target.value)}
        />
        <input
          className=" form-control"
          type="tel"
          placeholder="Phone number"
          value={phoneNumber}
          onChange={(e) => setPhonenumber(e.target.value)}
        />
        <input
          className=" form-control"
          type="email"
          placeholder="E-mail Adress"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <button className=" btn btn-primary">Submit</button>
      </form>
    </>
  );
}
export default AttorneyForm;
